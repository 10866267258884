import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { Media } from './Media';

const MediaPage = ({ data, location }) => {
  const { markdownRemark } = data;
  const { excerpt, html, frontmatter } = markdownRemark;
  const { title, seoTitle, description, youtubeId } = frontmatter;

  return (
    <Layout location={location}>
      <Seo
        location={location}
        title={seoTitle || title}
        description={description || excerpt}
      />
      <Media
        content={<div dangerouslySetInnerHTML={{ __html: html }} />}
        title={title}
        youtubeId={youtubeId}
      />
    </Layout>
  );
};

export default MediaPage;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        seoTitle
        description
        youtubeId
      }
    }
  }
`;
