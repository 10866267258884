import React from 'react';
import YouTube from 'react-youtube';
import ContentHeader from '../components/ContentHeader/ContentHeader';
import Section from '../components/Section/Section';
import './Media.scss';

export const Media = ({ title, content, youtubeId }) => {
  const opts = {
    height: '664',
    width: '1180',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      rel: 0,
      showinfo: 0,
      // modestbranding: 1,
    },
  };

  return (
    <article>
      <ContentHeader title={title} />
      <Section>
        {youtubeId && (
          <YouTube
            videoId={youtubeId} // defaults -> ''
            // id={string}                       // defaults -> ''
            className={'Media__youtube'} // defaults -> ''
            iframeClassName={'Media__youtube-iframe'} // defaults -> ''
            // style={object}                    // defaults -> {}
            // title={string}                    // defaults -> ''
            // loading={string}                  // defaults -> undefined
            opts={opts} // defaults -> {}
            // onReady={func}                    // defaults -> noop
            // onPlay={func}                     // defaults -> noop
            // onPause={func}                    // defaults -> noop
            // onEnd={func}                      // defaults -> noop
            // onError={func}                    // defaults -> noop
            // onStateChange={func}              // defaults -> noop
            // onPlaybackRateChange={func}       // defaults -> noop
            // onPlaybackQualityChange={func}    // defaults -> noop
          />
        )}

        <div itemProp="articleBody">{content}</div>
      </Section>
    </article>
  );
};
